import React from "react";

// markup
const Diwali = () => {

    return (
        <>
            <div className="diwali-main">
                <div className="cont-diwali">
                    <div className="card-diwali">
                        <img src="https://cdn.pixabay.com/photo/2021/10/27/02/11/diwali-banner-6745457__480.png" alt="Avatar" />
                        <div className="container-diwali"> 
                            {/* <div className="cake">
                                <div className="candle">
                                    <div className="flame"></div>
                                    </div>
                                </div> */}
                             <div className="type-js-diwali">
                                <p className="text-js-diwali">May this Diwali Light up New Dreams, Fresh Hopes, Undiscovered Avenues, Different Perspectives, and Everything Bright & Beautiful, And Fill Your Days with Pleasant Surprises & Moments.</p>
                            </div>
                            <h2 className="wish-diwali" style={{ display: 'none' }}>Wishing you</h2>
                            <h1 className="ml15-diwali">
                                <span className="word-diwali happy-word" style={{ display: 'none' }}>Happy</span>&nbsp;
                                <span className="word-diwali diwali-word" style={{ display: 'none' }}>Diwali</span>
                            </h1>
                            <h2 className="wish-diwali" style={{ display: 'none' }}>2022</h2>
                            <h1 className="credit-diwali">Made with <span style={{ color: 'tomato', fontSize: '20px' }}>&#10084;</span> by <a href="https://vasundhara.io" target="_blank">Vasundhara Infotech LLP</a></h1>
                        </div>
                    </div>
                </div>

                <canvas id="canva"></canvas>
                {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.min.js" integrity="sha512-aVKKRRi/Q/YV+4mjoKBsE4x3H+BkegoM/em46NNlCqNTmUYADjBbeNefNxYV7giUp0VxICtqdrbqU7iVaeZNXA==" crossOrigin="anonymous" referrerPolicy="no-referrer"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/animejs/2.0.2/anime.min.js"></script>
        <script src="../js/diwali.js"></script> */}
            </div>
        </>
    )
}

export default Diwali
